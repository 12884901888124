<template>
  <div align="center" class="component-container">
      <h2>{{ this.item.title }}</h2>
        Passage: {{ this.item.passage }} <br>
        Preacher: {{ this.item.speaker }} <br>
        Venue: {{ this.item.venue }} <br>
        Date: {{ this.item.date }} <br>
        <p> Topics: <span v-for="(topic, index) in this.item.topics" :key="topic.id">{{ topic.name }}<span v-if="!(index == this.item.topics.length - 1)">, </span></span></p>
        Notes: {{ this.item.notes }} <br>
        <a :href="this.item.link" target="_blank" class="sermon-link">Watch</a> | <a href="/" class="sermon-link">Back</a>
  </div>
</template>

<style scoped>
    /* used for the component's display area */
    .component-container {
        height: 100vh;
        overflow: scroll;
    }

    /* used for the fields of the form */
    .form-field {
        padding: 20px;
        width: min-content;
        position: relative;
    }

    /* used for the labels of the form */
    .form-label {
        display: block;
        float: left;
        position: absolute;
    }

    /*  used to chansge the appearance of form labels if their field has an error */
    .form-label-input-error {
        font-size: 8px;
        position: relative;
        color: red;
    }

    /*  used when a label's field is focused*/
    .form-label-input-focus {
        font-size: 8px;
        position: relative;
        color: #000080
    }

    /* used for form input elements when their field has an error */
    .form-input-error {
        color: red;
        border-color: red;
    }

    /*  used for form error text */
    .form-error {
        color: red;
    }


    /* used for form input elements */
    .form-input {
        width: 200px;
        border-top: none;
        border-left: none;
        border-right: none;
        font-family: inherit;
        font-size: 16px;
        background: whitesmoke;
        color: transparent;
    }
    /* used to for form input elements when they are focused */
    .form-input:focus {
        outline: none;
        border-color: #000080;
        color: inherit;
    }

    /* used for form input elements when their field is in use */
    .form-input-in-use {
        color: inherit;
    }

    /* used for form buttons */
    .form-button {
        background: whitesmoke;
        color: #000080;
        border: 2px solid #000080;
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
        padding: 6px 24px;
        margin-bottom: 10px;
    }

    /* used for form buttons when they are hovered over */
    .form-button:hover {
        background: #000080;
        color: white;
        border: 2px solid #000080;
    }

    /* used for form buttons when they are focused */
    .form-button:focus {
        background: #000080;
        color: white;
        border: 2px solid #000080;
        outline: none;
    }

    /* 
        used for form select elements
        named as *_custom to avoid conflict with bootstrap CSS
     */
    .form-select-custom {
        width: 200px;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom-width: 2px;
        border-radius: none;
        box-shadow: none;
        background: whitesmoke;
    }

    /* used for form select elements when they are focused */
    .form-select-custom:focus {
        outline: none;
        border-color: #000080;
        color: inherit;
    }

    /* media query used for form buttons when the user lacks the ability to hover */
    @media (hover:none) {
        .form-button {
            background: #000080;
            color: white;
            border: 2px solid #000080;
        }
    }

    .item-container {
        margin-bottom: 25px;
    }

    .sermon-link {
        color: blue;
    }

    .sermon-link:hover {
        color: purple;
    }

    .bold-text {
        font-weight: bold;
    }

</style>

<script>
// @ is an alias to /src
//import axios from 'axios'
import store from '.././store'

export default {
  name: 'Item',

  data() {
    return {
        item: {}
    }
  },

  methods: {      
  },
  
  components: {
  },

  /**
   * Lifecycle hook that runs when the compnent is created
   */
  created() {
      this.item = store.state.item;
  },

  beforeUnmount(){
      this.$store.dispatch("displayItemDetails", this.item);
  }
}
</script>
